const i18nDomains = require('./i18nDomains.json');

const deepMerge = (target, source) => {
  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (source[key] instanceof Object && !Array.isArray(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        deepMerge(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return target;
};

function getEnvDomains() {
  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    case 'dev':
      return i18nDomains.dev;
    case 'stage':
      return i18nDomains.stage;
    case 'prod':
      return i18nDomains.prod;
    default:
      throw new Error(`APP_ENV ${process.env.NEXT_PUBLIC_APP_ENV} is not supported`);
  }
}

const nextTranslateConfig = {
  logBuild: false,
  locales: [
    'bg',
    'bs',
    'cs',
    'da',
    'de',
    'el',
    'en',
    'es',
    'et',
    'fi',
    'fr',
    'hr',
    'hu',
    'it',
    'lt',
    'lv',
    'nl',
    'no',
    'pl',
    'pt',
    'ro',
    'ru',
    'sk',
    'sl',
    'sr',
    'sv',
    'tr',
    'uk',
  ],
  defaultLocale: 'pl',
  pages: {
    '*': ['common', 'footer'],
    '/': ['homePage', 'integrationsPage'],
    '/references': ['referencesPage'],
    '/references/sklep-biegacza': ['referencesPage'],
    '/references/r-gol': ['referencesPage'],
    '/references/4f': ['referencesPage'],
    '/references/wittchen': ['referencesPage'],
    '/pricing': ['pricingPage'],
    '/contact': ['contactPage'],
    '/SA-info': ['contactPage'],
    '/surveys': ['surveysPage', 'referencesPage'],
    '/review-translate': ['reviewTranslatePage', 'referencesPage'],
    '/crossborder-sales': ['reviewTranslatePage', 'referencesPage'],
    '/increase-sales': ['reviewsPage', 'referencesPage'],
    '/google': ['googlePage', 'referencesPage'],
    '/collecting-reviews': ['collectingReviewsPage', 'referencesPage'],
    '/client-contact': ['mediationSystemPage', 'referencesPage'],
    '/integrations': ['integrationsPage', 'referencesPage'],
    '/find-company': ['findCompanyPage'],
    '/categories': ['categoryPage'],
    '/categories/shops': ['categoryPage'],
    '/categories/services': ['categoryPage'],
    '/categories/restaurants': ['categoryPage'],
    '/categories/hotels': ['categoryPage'],
    '/categories/services/[country]': ['categoryPage'],
    '/categories/services/[country]/[region]': ['categoryPage'],
    '/categories/services/[country]/[region]/[city]': ['categoryPage'],
    '/categories/services/[country]/[region]/[city]/[category]': ['categoryPage'],
    '/categories/restaurants/[country]': ['categoryPage'],
    '/categories/restaurants/[country]/[region]': ['categoryPage'],
    '/restaurants/[country]/[region]/[city]': ['categoryPage'],
    '/categories/restaurants/[country]/[region]/[city]/[category]': ['categoryPage'],
    '/categories/hotels/[country]': ['categoryPage'],
    '/categories/hotels/[country]/[region]': ['categoryPage'],
    '/categories/hotels/[country]/[region]/[city]': ['categoryPage'],
    '/categories/hotels/[country]/[region]/[city]/[category]': ['categoryPage'],
    '/reviews/[slug]': ['profilePage'],
    '/why-us': ['whyUsPage'],
    '/hotel': ['hotelsPage'],
    '/badges': ['badgesPage', 'profilePage'],
    '/product/[productId]/[slug]/[uuid]': ['productPage'],
    '/company/[uuid]/product/[productId]': ['productPage', 'profilePage'],
    '/more-sales': ['reviewsPage', 'referencesPage'],
    '/traffic': ['googlePage', 'referencesPage'],
    '/product-polls': ['surveysPage', 'referencesPage'],
    '/review-collection': ['reviewCollectionPage', 'referencesPage'],
    '/our-team': ['ourTeamPage'],
  },
  localeDetection: false,
  domains: getEnvDomains(),
  loadLocaleFrom: async (lang, ns) => {
    const onlyPlSites = [];
    if (onlyPlSites.includes(ns)) {
      const locales = await import(`./locales/${lang}/${ns}.json`).then((m) => m.default);
      return locales;
    } else {
      const locales = await import(`./locales/${lang}/${ns}.json`).then((m) => m.default);
      const defaultLocales = await import(`./locales/en/${ns}.json`).then((m) => m.default);
      const mergedLocales = deepMerge(JSON.parse(JSON.stringify(defaultLocales)), locales);
      return mergedLocales;
    }
  },
};

module.exports = nextTranslateConfig;
